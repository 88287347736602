// src/contexts/AuthContext.jsx
import { createContext, useContext, useState, useEffect } from "react";
import { useUserStore } from "../store/UserAuthenticated";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  // Si usas Zustand, toma el usuario del store
  const { userA } = useUserStore();

  // Valores dummy para mantener la interfaz
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Simula que la carga terminó inmediatamente
    setLoading(false);
  }, []);

  // Funciones dummy que devuelven promesas resueltas
  const createUser = (email, password) => Promise.resolve({ email });
  const signIn = (email, password) => Promise.resolve({ email });
  const logout = () => Promise.resolve();
  const resetPassword = (email) => Promise.resolve();

  return (
    <UserContext.Provider
      value={{
        // Si hay usuario en el store, úsalo; de lo contrario, el estado dummy
        user: userA && userA.length !== 0 ? userA[0] : user,
        loading,
        createUser,
        signIn,
        logout,
        resetPassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("UserAuth debe usarse dentro de un AuthContextProvider");
  }
  return context;
};
