import React, { useEffect, useState } from "react";
import Logo from "../assets/img/logo1.png";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import { ImExit } from "react-icons/im";
import { useUserStore } from "../store/UserAuthenticated";

import io from "socket.io-client";
const socket = io("https://cslb.nexeraadvisors.com:5001");

const Navbar = () => {
  const { userA, removeUserA } = useUserStore();
  const { user, logout } = UserAuth();
  const [usersq, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    removeUserA();
    localStorage.removeItem("user");
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    const fetchUsersAndContractors = async () => {
      setLoading(true);
      const responseUsers = await fetch(
        "https://cslb.nexeraadvisors.com/api/users"
      );
      const users = await responseUsers.json();
      setUsers(users.filter((userf) => userf.email === userA[0].email)[0]);
      if (user) {
        socket.emit("addNewUser", user.email);
      } else {
        socket.disconnect();
      }
      setLoading(false);
    };
    fetchUsersAndContractors();
  }, []);

  return (
    <div className="w-[100%] h-[5vh] flex justify-end p-2 bg-slate-50 shadow-lg">
      <h2 className="text-slate-400 text-sm">Powered by:</h2>
      <Link to="/">
        <div>
          <div className="flex ml-4 mt-1">
            <img src={Logo} alt="" className="w-24 ml-2" />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Navbar;
