import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { UserAuth } from "../contexts/AuthContext";
import { useUserStore } from "../store/UserAuthenticated";
import {
  useGetTotalLicenses,
  useGetLicensesByCounty,
  useGetCompanyLicenses,
  useGetLastUpdateLic,
  useGetUserLicenses,
} from "../lib/react-query/queries";
import AreaGraph from "../components/AreaGraph";
import Carousel from "../components/Carousel";

const AdminDashboard = () => {
  const authContext = UserAuth();
  const { userA, removeUserA } = useUserStore();

  const { data: totalLicenses, isLoading } = useGetTotalLicenses();
  const count = totalLicenses?.count;
  const { data, isLoading2 } = useGetLicensesByCounty();
  const { data: companyLicenses, isLoading3 } = useGetCompanyLicenses(
    userA[0].company._id
  );
  const { data: userLicenses, isLoading5 } = useGetUserLicenses(userA[0]._id);
  const { data: lastUpdate, isLoading4 } = useGetLastUpdateLic();

  let countyLicenses = [];
  if (data) {
    countyLicenses = data;
  }

  //console.log(userA[0]._id)

  const userStates = userLicenses;

  const [counter, setCounter] = useState(0);
  const [counter2, setCounter2] = useState(0);
  const [counter3, setCounter3] = useState(0);
  const targetValue = count || 0;
  const targetValue2 = 400;
  const targetValue3 =
    userStates?.New +
      userStates?.Qualified +
      userStates?.Discussion +
      userStates?.Negotiation +
      userStates?.Won +
      userStates?.Lost || 0;

  //console.log(userStates)
  const duration = 1500;
  useEffect(() => {
    const startTime = Date.now();

    const intervalId = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      setCounter(Math.floor(progress * targetValue).toLocaleString());
      setCounter2(Math.floor(progress * targetValue2).toLocaleString());
      setCounter3(Math.floor(progress * targetValue3).toLocaleString());
    }, 10);
    return () => clearInterval(intervalId);
  }, [targetValue, duration, userStates]);

  /* if (authContext.loading) return <p>Loading...</p>; */

  return (
    <Layout>
      <div className="w-[86vw]">
        <Carousel countyLicenses={countyLicenses} />
        <div className=" flex w-[100%] ml-[1vw] gap-[1.5vw] mt-4">
          <div className=" w-[29vw] border border-gray-200 py-4 text-center shadow-2xl rounded-xl bg-gray-100">
            <p className="text-orange-400">Contractors on global DB:</p>
            <p className="text-6xl font-bold text-orange-500">{counter}</p>
          </div>
          <div className=" w-[29vw] border border-gray-200 py-6 text-center shadow-2xl rounded-xl bg-orange-50">
            <p className="text-gray-500">
              Last update: {lastUpdate && lastUpdate.date}
            </p>
            <p className="text-6xl font-bold text-gray-500">
              {lastUpdate && lastUpdate.count}
              <small className="text-sm">added</small>
            </p>
          </div>
          <div className=" w-[29vw] border border-gray-200 py-6 text-center shadow-2xl rounded-xl bg-gray-100">
            <p className="text-orange-400">Contractors on your own DB:</p>
            <p className="text-6xl font-bold text-orange-500">{counter3}</p>
          </div>
        </div>
        <div className="flex w-[100%] ml-[1vw] gap-[3vw] mt-[3vh]">
          <div className="w-[58vw] border border-gray-200 rounded-xl h-[50vh] bg-gray-100 shadow-2xl pt-4">
            <AreaGraph />
          </div>
          <div className="w-[29vw] border border-orange-200 bg-orange-50 h-[50vh] shadow-2xl rounded-2xl p-1">
            <h2 className="text-gray-400 font-semibold text-xl text-center mb-2">
              My Leads
            </h2>
            <div className="bg-violet-200 mx-2 text-gray-400 font-semibold flex justify-between py-1.5 px-4 mb-1 rounded-lg">
              <p>New</p>
              <p>{userStates ? userStates.New : 0}</p>
            </div>
            <div className="bg-sky-200 mx-2 text-gray-400 font-semibold flex justify-between py-1.5 px-4 mb-1 rounded-lg">
              <p>Qualified</p>
              <p>{userStates ? userStates.Qualified : 0}</p>
            </div>
            <div className="bg-green-200 mx-2 text-gray-400 font-semibold flex justify-between py-1.5 px-4 mb-1 rounded-lg">
              <p>Discussion</p>
              <p>{userStates ? userStates.Discussion : 0}</p>
            </div>
            <div className="bg-amber-200 mx-2 text-gray-400 font-semibold flex justify-between py-1.5 px-4 mb-1 rounded-lg">
              <p>Negotiation</p>
              <p>{userStates ? userStates.Negotiation : 0}</p>
            </div>
            <div className="bg-orange-200 mx-2 text-gray-400 font-semibold flex justify-between py-1.5 px-4 mb-1 rounded-lg">
              <p>Won</p>
              <p>{userStates ? userStates.Won : 0}</p>
            </div>
            <div className="bg-rose-200 mx-2 text-gray-400 font-semibold flex justify-between py-1.5 px-4 mb-1 rounded-lg">
              <p>Lost</p>
              <p>{userStates ? userStates.Lost : 0}</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminDashboard;
