import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import axios from "axios";
import { UserAuth } from "../contexts/AuthContext";

const LicencesOverview = () => {
  const authContext = UserAuth();
  const [licenseData, setLicenseData] = useState([]);
  const [error, setError] = useState(null);

  // Estados para paginación
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Estados para filtros
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedSeller, setSelectedSeller] = useState("");

  useEffect(() => {
    axios
      .get(
        "https://cslb.nexeraadvisors.com/api/license/company/65a97df3899c0f9b71e2a6de"
      )
      .then((response) => {
        setLicenseData(response.data);
      })
      .catch((err) => {
        console.error("Error al obtener los datos de licencia:", err);
        setError(err);
      });
  }, []);

  // Opciones para el selector de state (únicas y ordenadas)
  const stateOptions = useMemo(() => {
    return [...new Set(licenseData.map((l) => l.State).filter(Boolean))].sort();
  }, [licenseData]);

  // Opciones para el selector de seller (a partir de userId.fullname)
  const sellerOptions = useMemo(() => {
    return [
      ...new Set(
        licenseData
          .map((l) =>
            l.userId && l.userId.fullname ? l.userId.fullname : null
          )
          .filter(Boolean)
      ),
    ].sort();
  }, [licenseData]);

  // Filtrado de datos según los filtros ingresados
  const filteredData = useMemo(() => {
    return licenseData.filter((license) => {
      const lowerQuery = searchQuery.toLowerCase();
      const matchesSearch =
        searchQuery === "" ||
        (license.LicenseNo &&
          String(license.LicenseNo).toLowerCase().includes(lowerQuery)) ||
        (license.businessName &&
          String(license.businessName).toLowerCase().includes(lowerQuery)) ||
        (license.classification &&
          String(license.classification).toLowerCase().includes(lowerQuery));

      const matchesState =
        selectedState === "" || license.State === selectedState;
      const matchesSeller =
        selectedSeller === "" ||
        (license.userId && license.userId.fullname === selectedSeller);

      return matchesSearch && matchesState && matchesSeller;
    });
  }, [licenseData, searchQuery, selectedState, selectedSeller]);

  // Cálculos para la paginación basados en los datos filtrados
  const totalRecords = filteredData.length;
  const totalPages = Math.ceil(totalRecords / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentData = filteredData.slice(startIndex, startIndex + rowsPerPage);

  // Función para manejar el cambio en el selector de rows per page
  const handleRowsPerPageChange = (e) => {
    const newRows = Number(e.target.value);
    setRowsPerPage(newRows);
    setCurrentPage(1);
  };

  // Función para cambiar la página
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Función para "aplanar" el objeto y extraer solo los valores deseados para CSV
  const flattenObject = (obj) => {
    const flat = {};
    for (const key in obj) {
      if (!Object.prototype.hasOwnProperty.call(obj, key)) continue;
      const value = obj[key];

      if (key === "companyId" && value && typeof value === "object") {
        flat["companyId"] = value.name || "";
      } else if (key === "userId" && value && typeof value === "object") {
        flat["userId"] = value.fullname || "";
      } else if (key === "notes") {
        flat["notes"] = value;
      } else {
        flat[key] =
          typeof value === "object" && value !== null
            ? JSON.stringify(value)
            : value;
      }
    }
    return flat;
  };

  // Función que convierte un array de objetos a CSV, separando las notes en columnas note1, date1, etc.
  const convertArrayOfObjectsToCSV = (array) => {
    if (!array || !array.length) return "";
    const flattenedArray = array.map(flattenObject);
    let maxNotes = 0;
    flattenedArray.forEach((item) => {
      if (Array.isArray(item.notes)) {
        maxNotes = Math.max(maxNotes, item.notes.length);
      }
    });
    const processedArray = flattenedArray.map((item) => {
      const newItem = { ...item };
      if (Array.isArray(newItem.notes)) {
        const notesArray = newItem.notes;
        delete newItem.notes;
        for (let i = 0; i < maxNotes; i++) {
          newItem[`note${i + 1}`] = notesArray[i]
            ? notesArray[i].text || ""
            : "";
          newItem[`date${i + 1}`] = notesArray[i]
            ? notesArray[i].date || ""
            : "";
        }
      } else {
        for (let i = 0; i < maxNotes; i++) {
          newItem[`note${i + 1}`] = "";
          newItem[`date${i + 1}`] = "";
        }
      }
      return newItem;
    });
    const headersSet = new Set();
    processedArray.forEach((item) => {
      Object.keys(item).forEach((key) => headersSet.add(key));
    });
    const headers = Array.from(headersSet);
    const csvHeader = headers.join(",");
    const csvRows = processedArray.map((item) => {
      return headers
        .map((header) => {
          let cell = item[header] !== undefined ? item[header] : "";
          if (typeof cell === "string") {
            cell = cell.replace(/"/g, '""');
            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }
          }
          return cell;
        })
        .join(",");
    });
    return [csvHeader, ...csvRows].join("\n");
  };

  // Función para descargar el CSV usando los datos filtrados
  const downloadCSV = () => {
    if (
      filteredData &&
      Array.isArray(filteredData) &&
      filteredData.length > 0
    ) {
      const csvData = convertArrayOfObjectsToCSV(filteredData);
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "licenseData.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Función para limpiar los filtros
  const clearFilters = () => {
    setSearchQuery("");
    setSelectedState("");
    setSelectedSeller("");
    setCurrentPage(1);
  };

  return (
    <Layout>
      <div className="p-4 h-[93vh] shadow-lg flex flex-col">
        {/* Sección Superior (10% del contenedor): Título, filtros y botones */}
        <div className="h-[10%] flex flex-col justify-center">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold">Licenses Overview</h1>
            <div className="flex items-center gap-4">
              <button
                onClick={downloadCSV}
                className="border rounded px-3 py-1 text-sm bg-green-500 text-white hover:bg-green-600"
              >
                Export to CSV
              </button>
            </div>
          </div>
          {/* Filtros */}
          <div className="flex flex-wrap items-center gap-4 mt-2">
            <input
              type="text"
              className="border rounded px-2 py-1 text-sm"
              placeholder="Search by License No, Business Name o Classification"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <select
              className="border rounded px-2 py-1 text-sm"
              value={selectedState}
              onChange={(e) => setSelectedState(e.target.value)}
            >
              <option value="">All states</option>
              {stateOptions.map((state, idx) => (
                <option key={idx} value={state}>
                  {state}
                </option>
              ))}
            </select>
            <select
              className="border rounded px-2 py-1 text-sm"
              value={selectedSeller}
              onChange={(e) => setSelectedSeller(e.target.value)}
            >
              <option value="">All sellers</option>
              {sellerOptions.map((seller, idx) => (
                <option key={idx} value={seller}>
                  {seller}
                </option>
              ))}
            </select>
            <button
              onClick={clearFilters}
              className="border rounded px-3 py-1 text-sm bg-red-500 text-white hover:bg-red-600"
            >
              Clear Filters
            </button>
          </div>
        </div>

        {/* Sección Media (80% del contenedor): Tabla */}
        <div className="h-[80%] mt-2 border rounded shadow overflow-y-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 sticky top-0 z-10">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  License No
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Business Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Classification
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  State
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Seller
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentData.length > 0 ? (
                currentData.map((license) => (
                  <tr key={license._id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {license.LicenseNo}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      <Link
                        to={`/contractor-detail/${license._id}`}
                        className="font-semibold text-orange-700 hover:underline hover:text-orange-600"
                      >
                        {license.businessName}
                      </Link>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {license.classification}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {license.State}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {license.userId ? license.userId.fullname : ""}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="5"
                    className="px-6 py-4 text-center text-sm text-gray-500"
                  >
                    No records found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Sección Inferior (10% del contenedor): Paginación */}
        <div className="h-[10%] mt-2 flex flex-col md:flex-row items-center justify-between">
          <div className="text-sm text-gray-600">
            Total Records: {totalRecords}
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-600">Results per page:</span>
              <select
                className="border rounded px-2 py-1 text-sm"
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className="flex items-center space-x-2">
              <button
                className={`px-3 py-1 border rounded ${
                  currentPage === 1
                    ? "text-gray-400 border-gray-300 cursor-not-allowed"
                    : "text-gray-700 border-gray-700 hover:bg-gray-100"
                }`}
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  className={`px-3 py-1 border rounded ${
                    currentPage === i + 1
                      ? "bg-blue-500 text-white"
                      : "bg-white text-gray-700 hover:bg-gray-100"
                  }`}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </button>
              ))}
              <button
                className={`px-3 py-1 border rounded ${
                  currentPage === totalPages || totalPages === 0
                    ? "text-gray-400 border-gray-300 cursor-not-allowed"
                    : "text-gray-700 border-gray-700 hover:bg-gray-100"
                }`}
                disabled={currentPage === totalPages || totalPages === 0}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LicencesOverview;
