//src/routes/ProtectedRoute.jsx

import { Navigate, useLocation } from "react-router-dom";
import { UserAuth } from "../contexts/AuthContext";
import { useUserStore } from "../store/UserAuthenticated";

const ProtectedRoute = ({ children }) => {
  const { userA } = useUserStore();
  const { loading } = UserAuth();
  const location = useLocation();

  if (loading) return <p>Loading...</p>;

  return userA && userA.length !== 0 ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default ProtectedRoute;
